import {Controller} from "@hotwired/stimulus"

const NAVBAR_HEIGHT = 49

export class NavigationController extends Controller {
    static targets = ["noCollapse"];

    connect() {
        this.width = 0
        this.observer = new ResizeObserver(this.onResize)
        this.observer.observe(this.element)
    }

    disconnect() {
        this.observer.unobserve(this.element)
    }

    onResize = () => {
        if (this.width !== this.element.offsetWidth) {
            this.width = this.element.offsetWidth
            this.element.classList.add('navbar-expand-lg')
            this.noCollapseTarget.classList.add('order-lg-last')

            if (this.element.offsetHeight > NAVBAR_HEIGHT) {
                this.element.classList.remove('navbar-expand-lg')
                this.noCollapseTarget.classList.remove('order-lg-last')
            } else {

            }
        }
    }
}