import {config, dom, library} from '@fortawesome/fontawesome-svg-core'
import {faAt, faCheck, faCheckCircle, faCog, faDesktop, faEdit, faExclamationTriangle, faGlobe, faHouseFire, faLock, faLockOpen, faMobileAlt, faPhone, faPlus, faSignOutAlt, faTabletAlt, faTimesCircle, faTools, faTrash, faUser, faUserCog, faUsersCog, faUserShield} from '@fortawesome/free-solid-svg-icons'
import {faAndroid, faApple} from '@fortawesome/free-brands-svg-icons'

config.mutateApproach = 'sync';

library.add(
    faTimesCircle,
    faCheck,
    faCheckCircle,
    faAt,
    faCog,
    faDesktop,
    faEdit,
    faExclamationTriangle,
    faGlobe,
    faLock,
    faLockOpen,
    faMobileAlt,
    faPhone,
    faPlus,
    faSignOutAlt,
    faTabletAlt,
    faTools,
    faTrash,
    faUser,
    faUserCog,
    faUsersCog,
    faUserShield,
    faAndroid,
    faApple,
    faHouseFire
)

dom.watch();
