import {Controller} from "@hotwired/stimulus"
import {iframeResize} from 'iframe-resizer'
import ProgressBar from 'progressbar.js'

export class IFrameController extends Controller {
    static targets = ["loading", "content"];

    initialize() {
        this.animationDuration = 300/*ms*/
        this.value = 0

        this.bar = new ProgressBar.Line(this.loadingTarget, {
            strokeWidth: 1,
            duration: this.animationDuration,
            easing: 'easeInOut',
            color: '#C4021F',
            trailWidth: 0,
            svgStyle: {width: '100%', height: '1px'}
        });
    }

    connect() {
        iframeResize({
            onMessage: (data) => {
                if (data.message.pageTitle !== undefined && data.message.pageTitle !== null) {
                    document.title = data.message.pageTitle
                }
                history.replaceState(null, null, data.message.pageLocation.replace('/c/', '/'))
            },
        }, this.contentTarget);

        this.contentTarget.addEventListener("load", this.endLoading);
    }

    setLoadingValue(value) {
        this.value = value
        this.bar.animate(this.value)
    }

    startLoading = () => {
        this.setLoadingValue(0.3)
        this.trickleInterval = window.setInterval(this.trickle, this.animationDuration)
    }

    endLoading = () => {
        window.clearInterval(this.trickleInterval)
        delete this.trickleInterval

        this.setLoadingValue(1)
        window.setTimeout(() => this.setLoadingValue(0), 300)

        // re add listener, since contentWindow loses reference after new ste gets loaded
        this.addStartLoadingEvent()
    }

    trickle = () => {
        this.setLoadingValue(this.value + Math.random() / 100)
    }

    addStartLoadingEvent() {
        // only use custom progressbar if Turbolinks is not used, since Turbolinks has it's own already build in
        if (this._turbo_not_supported() && this._turbolinks_not_supported()) {
            // make sure no old event is listening and add listener
            this.contentTarget.contentWindow.removeEventListener("beforeunload", this.startLoading);
            this.contentTarget.contentWindow.addEventListener("beforeunload", this.startLoading);
        }
    }

    disconnect() {
        if (this.contentTarget.contentWindow) {
            this.contentTarget.contentWindow.removeEventListener("beforeunload", this.startLoading);
        }
        this.contentTarget.addEventListener("load", this.endLoading);
    }

    _turbo_not_supported() {
        !('Turbo' in this.contentTarget.contentWindow.window)
    }

    _turbolinks_not_supported() {
        !('Turbolinks' in this.contentTarget.contentWindow.window) || !this.contentTarget.contentWindow.window.Turbolinks.supported
    }
}