import {Controller} from "@hotwired/stimulus"
import IMask from 'imask';

export class VerificationController extends Controller {
    static targets = ["codeInput"];

    connect() {
        this.inputMask = IMask(this.codeInputTarget, {
            mask: '0 0 0 0 0 0',
            lazy: false,
            placeholderChar: '__'
        });
        this.inputMask.on("complete", () => {
            this.element.requestSubmit()
        });
    }

    disconnect() {
        this.inputMask.destroy()
    }
}