import {Controller} from "@hotwired/stimulus"
import {post} from '@rails/request.js'
import Swal from 'sweetalert2'

export class SendOTPController extends Controller {
    async show() {
        Swal.fire({
            title: "SMS wird gesendet...",
            text: "Bitte warten",
        });
        Swal.showLoading();
        try {
            const response = await post('/verify/request_code_per_sms', {contentType: "application/json"})
            const json = await response.json
            Swal.hideLoading();
            Swal.fire(json);
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Beim sender der SMS ist leider ein Fehler passiert!',
                text: 'Bitte versuchen Sie es erneut',
            })
        }
    }
}