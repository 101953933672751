import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";
import * as bootstrap from 'bootstrap'

const LOAD_DEPARTMENTS_FOR_ORGANIZATIONS = ['ff', 'rk', 'asb', 'lsz']
const LOAD_HOME_FIRE_DEPARTMENTS_FOR_ORGANIZATIONS = ['ff']

export class OrganizationsController extends Controller {
    static values = {
        departmentId: Number,
        homeFireDepartmentId: Number,
    }
    static targets = ["organizationsInput", "departmentsInput", "homeFireDepartmentsInput", "departmentsWrapper", "homeFireDepartmentsWrapper"];

    connect() {
        this.departmentWrapper = new bootstrap.Collapse(this.departmentsWrapperTarget, {toggle: false})
        this.organisationsSelect = new TomSelect(this.organizationsInputTarget, {
            placeholder: "Bitte Organisation auswählen"
        })
        this.departmentsSelect = new TomSelect(this.departmentsInputTarget, {
            placeholder: "Bitte Dienststelle auswählen"
        })
        this.homeFireDepartmentsSelect = new TomSelect(this.homeFireDepartmentsInputTarget, {
            placeholder: "Bitte Heimatfeuerwehr auswählen"
        })

        this.organisationsSelect.on("change", this.onOrganizationChange)

        let currentVal = this.organisationsSelect.getValue();
        if (LOAD_DEPARTMENTS_FOR_ORGANIZATIONS.includes(currentVal)) {
            this.onOrganizationChange(currentVal)
        } else {
            this.departmentsSelect.disable();
            this.homeFireDepartmentsSelect.disable();
        }
    }

    onOrganizationChange = async (value) => {
        this.departmentsSelect.disable();
        this.homeFireDepartmentsSelect.disable();
        this.departmentsSelect.clear(true);
        this.homeFireDepartmentsSelect.clear(true);
        this.departmentsSelect.clearOptions();
        this.homeFireDepartmentsSelect.clearOptions();
        this.departmentsSelect.clearCache();
        this.homeFireDepartmentsSelect.clearCache();

        if (LOAD_DEPARTMENTS_FOR_ORGANIZATIONS.includes(value)) {
            const items = await fetch(`/departments/${value}`, {headers: {'Content-Type': 'application/json'}})
            let departments = await items.json()
            this.departmentsSelect.addOptions(structuredClone(departments))
            if (this.hasDepartmentIdValue) {
                this.departmentsSelect.addItem(this.departmentIdValue)
            }
            this.departmentsSelect.enable()
            this.departmentWrapper.show()
            if (LOAD_HOME_FIRE_DEPARTMENTS_FOR_ORGANIZATIONS.includes(value)) {
                this.homeFireDepartmentsSelect.addOptions(structuredClone(departments))
                if (this.homeFireDepartmentIdValue) {
                    this.homeFireDepartmentsSelect.addItem(this.homeFireDepartmentIdValue)
                }
                this.homeFireDepartmentsSelect.enable()
                this.homeFireDepartmentsWrapperTarget.classList.remove("d-none")
            } else {
                this.homeFireDepartmentsWrapperTarget.classList.add("d-none")
            }
        } else {
            this.departmentWrapper.hide()
            this.homeFireDepartmentsWrapperTarget.classList.add("d-none")
        }
    }

    disconnect() {
        this.organisationsSelect.destroy();
        this.departmentsSelect.destroy();
        this.homeFireDepartmentsSelect.destroy();
    }
}