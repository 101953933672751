import * as bootstrap from 'bootstrap'

document.addEventListener("turbo:render", function () {
    document.querySelectorAll('.toast').forEach((element) => {
        new bootstrap.Toast(element).show()
    })

    document.querySelectorAll('.dropdown').forEach((element) => {
        if (element.querySelector('.dropdown-menu .active')) {
            element.querySelector('.dropdown-toggle').classList.add("active");
        }
    })
});

document.addEventListener("turbo:before-cache", function () {
    document.querySelectorAll('.toast').forEach((element) => {
        element.remove();
    })
});